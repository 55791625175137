import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { destroyCookie, parseCookies, setCookie } from 'nookies'
import { CreatorData } from '@/types/Creator.type'
import { useFanmeBackendGet } from '@/libs/fanme_backend'

declare global {
  interface Window {
    gapi: any
  }
}

export type UserPermissions = {
  can_install_app?: boolean
}

type AuthContextProps = {
  token: string | null | undefined
  fetched: boolean
}

type Props = {
  children: React.ReactChild
}

type CurrentUserContextProps = {
  currentUser: CreatorData | null
  fetched: boolean
  refreshCurrentUser: () => Promise<any>
  userPermissions: UserPermissions
  userNotExists: boolean
}

const AuthContext = createContext<AuthContextProps>({ token: undefined, fetched: false })
const { publicRuntimeConfig } = getConfig()
const authProcessingKey = 'fanme.auth.at'
const COOKIE_MAX_AGE = 365 * 24 * 60 * 60 // 1年

export const AuthProvider = ({ children }: Props) => {
  const [resultToken, setResultToken] = useState('')
  const [fetched, setFetched] = useState(false)
  const { asPath, isReady, push, query, replace } = useRouter()

  const isExcludePath = (path: string) => {
    const resultPath = ['/', '/terms', '/privacy', '/contact', '/about']
    // LPとランキングはログイン不要でも参照可能なページになるので対象外
    const lpPath = /^\/lp\/.*/g
    const rankingPath = /^\/ranking\/.*/g
    // 各クリエイターの@xxxxをトップとするページも対象外
    const accountIdentityPath = path.match(/^\/@[\w\d_-]+/g)

    return (
      resultPath.includes(path) ||
      lpPath.test(path) ||
      rankingPath.test(path) ||
      (accountIdentityPath && accountIdentityPath.length > 0)
    )
  }

  const retrieveToken = () => {
    const cookies = parseCookies()
    const tokenKey = process.env.NEXT_PUBLIC_TOKEN_KEY || 'fanme_token'
    return [tokenKey, cookies[tokenKey]]
  }

  const updateLoggedInStatus = () => {
    // window.dataLayer に logged_in が存在するか確認
    const loggedInExists = window.dataLayer.some(data => 'logged_in' in data)

    // 存在する場合にのみフィルターで削除処理を行う
    if (loggedInExists) {
      window.dataLayer = window.dataLayer.filter(data => !('logged_in' in data))
    }

    window.dataLayer.push({ logged_in: true })
  }

  const doAuth = useCallback(async (): Promise<any> => {
    const [tokenKey, token] = retrieveToken()
    const now = Date.now()
    if ((!token || query.authorize) && isReady) {
      if (!isExcludePath(location.pathname) || query.authorize) {
        const lastAuthTime = parseInt(localStorage.getItem(authProcessingKey) || '0')
        if (lastAuthTime - now >= 0 && lastAuthTime - now < 5000) {
          // 5秒間ロックする（複数のタブが実行されるおそれがあるので、localstorageを利用してprocessをロックする）
          await new Promise(resolve => {
            // 1秒を待ってからもう一回実行する
            setTimeout(() => resolve(null), 1000)
          })
          return await doAuth()
        }
        localStorage.setItem(authProcessingKey, `${now}`)
        try {
          const res = await fetch(`${publicRuntimeConfig.API_BASE_URL}/creators/id_token`, {
            credentials: 'include',
          })
          if (res.status != 200) {
            // 認証失敗の場合はもう一度認証を行う
            const currentUrl = location.href
            const rtUrl = currentUrl.replace(/(\??)authorize=true(&?)/gm, '$1')
            location.href = `${publicRuntimeConfig.AUTH_URL}?return_url=${rtUrl}`
          } else {
            // 認証情報を保存する
            const data = await res.json()
            setCookie(null, tokenKey, data.id_token, {
              path: '/',
              sameSite: 'lax',
              maxAge: COOKIE_MAX_AGE,
            })
            setResultToken(data.id_token)

            updateLoggedInStatus()

            if (query.authorize) {
              const path = asPath.replace(/(\??)authorize=true(&?)/gm, '$1')
              history.pushState(null, '', `${publicRuntimeConfig.FRONT_URL}` + path)
            }
            if (query.purchase) {
              await replace(asPath.replace(/(\??)purchase=true(&?)/gm, '$1'))
            }
            if (query.addcart) {
              await replace(asPath.replace(/(\??)addcart=true(&?)/gm, '$1'))
            }
          }
        } catch (e) {
          console.error(e)
        } finally {
          localStorage.removeItem(authProcessingKey)
        }
      }
    } else {
      setResultToken(token)
    }
    setFetched(true)
  }, [query, asPath])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const onTabStateChanged = (e: any) => {
        if (!e.target.hidden && fetched) {
          // タブがアクティブになった時にトークンを再取得する
          const [tokenKey, token] = retrieveToken()
          if (!token) {
            setResultToken('')
          } else {
            setResultToken(token)
          }
        }
      }
      window.addEventListener('visibilitychange', onTabStateChanged)
      return () => {
        window.removeEventListener('visibilitychange', onTabStateChanged)
      }
    }
  }, [fetched])

  useEffect(() => {
    doAuth()
  }, [isReady, query])

  return (
    <AuthContext.Provider value={{ token: resultToken, fetched: fetched }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export const destroyCookieFunc = async () => {
  await destroyFanmeCookie()

  const iframeElm = (document && (document.getElementById('mini-app') as HTMLIFrameElement))
    ?.contentWindow
  if (iframeElm) {
    iframeElm.postMessage({ action: 'destroy_cookie' }, publicRuntimeConfig.FRAME_ORIGIN)
  }
}

const destroyFanmeCookie = async () => {
  const response = await fetch(`${publicRuntimeConfig.FRONT_URL}/destroy-cookie`, {
    method: 'POST',
  })
  if (!response.ok) {
    throw new Error('Failed to destroy cookie')
  }
  return true
}

const CurrentUserContext = createContext<CurrentUserContextProps>({
  currentUser: null,
  refreshCurrentUser: async () => {},
  fetched: false,
  userPermissions: {},
  userNotExists: true,
})

export const CurrentUserProvider = ({ children }: Props) => {
  const { token, fetched } = useAuth()
  const [userFetched, setUserFetched] = useState(false)
  const [currentUser, setCurrentUser] = useState<CreatorData | null>(null)
  const { data: permissionData } = useFanmeBackendGet<any>(
    currentUser ? '/payment/seller/permissions' : null,
  )
  const router = useRouter()

  const permissions: UserPermissions = permissionData || {}

  const retrieveCurrentUser = async (token: string) => {
    const response = await fetch(`${publicRuntimeConfig.API_BASE_URL}/creators/self`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    if (!response.ok) {
      throw new Error('Network response was not ok')
    }
    return await response.json()
  }

  useEffect(() => {
    if (fetched && token) {
      ;(async () => {
        try {
          return await retrieveCurrentUser(token)
        } catch (e) {
          const currentUrl = location.href
          const rtUrl = currentUrl.replace(/(\??)authorize=true(&?)/gm, '$1')
          // トークンの有効期限が切れている場合があるので、再認証を行う redirect to https://bff.fanme.link/creators/auth/fanme
          window.location.href = `${publicRuntimeConfig.AUTH_URL}?return_url=${rtUrl}`
        } finally {
          setUserFetched(true)
        }
      })().then(user => {
        setCurrentUser(user)
      })
    } else if (currentUser) {
      // ログアウト時にcurrentUserをnullにする
      setCurrentUser(null)
    }
  }, [fetched, token])

  useEffect(() => {
    if (currentUser) {
      if (router.pathname.startsWith('/signup') && currentUser.filled_profile) {
        router.push(`/@${currentUser.account_identity}`)
      }
    }
  }, [currentUser])

  const refreshCurrentUser = useCallback(async () => {
    if (token) {
      setCurrentUser(await retrieveCurrentUser(token))
    } else {
      setCurrentUser(null)
    }
  }, [token])

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: currentUser,
        userPermissions: permissions,
        userNotExists: fetched && userFetched && !currentUser,
        refreshCurrentUser,
        fetched: fetched && userFetched,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
export const useCurrentUser = () => useContext(CurrentUserContext)
